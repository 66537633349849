import React from "react"
import styled, { keyframes } from "styled-components"
import { colors } from "../theme"
import Modal from "styled-react-modal"
import { injectIntl } from "gatsby-plugin-intl"
import closeIcon from "../images/modal-close.png"
import { TranslationContext } from "../translate"
import get from "lodash/get"

const backgroundAppear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const modalAppear = keyframes`
  0% { opacity: 0; transform: scale(0.95); }
  100% { opacity: 1;  transform: scale(1); }
`

const appearFromBottom = keyframes`
  0% { opacity: 0; transform: translateY(100%); }
  100% { opacity: 1; transform: translateY(0%); }
`

const StyledModal = Modal.styled`
  width: 100%;
  max-width: 55em;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  color: #ffffff;
  animation: ${modalAppear} 200ms ease-out;
  opacity: 1;
  p {
    font-style: normal !important;
    color: #ffffff !important;
  }
  a {
    color: #ffffff !important;
    font-style: normal !important;
  }
`

const HeaderRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5em 0 0 0;
    @media (min-width: 769px) {
        flex-direction: row;
        margin: 0;
    }
`


const ContentRow = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
        flex-direction: row;
    }
`


const Sidebar = styled.div`
    background: #f0f0f0;
    padding: 2em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2em;
    color: #303030;
    @media (min-width: 769px) {
        width: 33.33%;
    }
`


const CoverImage = styled.img`
    width: 100%;
    object-fit: cover;
    @media (min-width: 769px) {
        width: 33.33%;
    }
`

const ModalTitle = styled.h1`
    background: ${colors.mustikka} !important;
    width: 100%;
    padding: 0.3em 0.6em;
    font-size: 3em;
    line-height: 0.9em;
    color: #ffffff !important;
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    word-break: break-word;
    @media (min-width: 769px) {
        font-size: 3em;
        flex: 1;
        width: auto;
    }
`

const ModalContent = styled.div`
    background: #fff;
    color: #303030;
    white-space: pre-wrap;
    padding: 2em;
    line-height: 1.4em;
    box-sizing: border-box;
    h1, h2, h3, h4, h5, h6 {
        color: ${colors.mustikka} !important;
    }
    @media (min-width: 769px) {
        flex: 1;
        width: auto;
    }
`

const CloseButton = styled.button`
    border: none;
    appearance: none;
    width: 3em;
    height: 3em;
    display: block;
    position: fixed;
    top: 2em;
    right: 2em;
    background: transparent url(${closeIcon}) no-repeat center center;
    background-size: contain;
    cursor: pointer;
    z-index: 102;
`


const SidebarItem = styled.div`

`


const SidebarItemTitle = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
`


const SidebarItemContent = styled.div`
    font-size: 2em;
    font-weight: 300;
`

const InterestedToast = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
    text-align: center;
    padding: 1em 4.5em;
    background: ${colors.mustikka};
    box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
    animation: ${appearFromBottom} 300ms ease-out forwards;
    transform: translateY(100%);
    animation-delay: 3s;
    opacity: 0;
`

const pricingUnitName = (isFinnish, unit) => {
    const pricingUnitNames = {
        "person": {
            "fi": "henkilö",
            "en": "person"
        },
        "day": {
            "fi": "päivä",
            "en": "day"
        },
    }
    return get(pricingUnitNames, [unit, isFinnish ? "fi" : "en"], unit)
}

const durationType = (isFinnish, unit) => {
    const durationTypes = {
        "hours": {
            "fi": "tuntia",
            "en": "hours"
        },
        "minutes": {
            "fi": "minuuttia",
            "en": "minutes"
        },
    }
    return get(durationTypes, [unit, isFinnish ? "fi" : "en"], unit)
}


const ExperienceModal = ({intl, experience, onClose}) => {
  const isFinnish = intl.locale === "fi"
  const isOpen = experience != null
  const info = experience.productInformations.find((i) => i.language === (isFinnish ? "fi" : "en"))
  const coverImage = experience.productImages.find((i) => i.coverPhoto === true) || experience.productImages[0]

  return <TranslationContext.Consumer>{(t) =>
    <>
    <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <HeaderRow>
        <CoverImage src={coverImage.thumbnailUrl} />
        <ModalTitle>{info.name}</ModalTitle>
      </HeaderRow>
      <ContentRow>
        <Sidebar>
            {experience.productPricings && experience.productPricings.map((p) => {
                return <SidebarItem>
                    <SidebarItemTitle>
                        {isFinnish ? "Hinta" : "Price"}
                    </SidebarItemTitle>
                    <SidebarItemContent>
                        {p.fromPrice} € / {pricingUnitName(isFinnish, p.pricingUnit)}
                    </SidebarItemContent>
                </SidebarItem>
            })}

            {experience.productCapacities && experience.productCapacities.map((p) => {
                return <SidebarItem>
                    <SidebarItemTitle>
                        {isFinnish ? "Ryhmäkoko" : "Group size"}
                    </SidebarItemTitle>
                    <SidebarItemContent>
                        {p.min} - {p.max}
                    </SidebarItemContent>
                </SidebarItem>
            })}

            {experience.duration && <SidebarItem>
                <SidebarItemTitle>
                    {isFinnish ? "Kesto" : "Duration"}
                </SidebarItemTitle>
                <SidebarItemContent>
                    {experience.duration} {durationType(isFinnish, experience.durationType)}
                </SidebarItemContent>
            </SidebarItem>}
        </Sidebar>
        <ModalContent>
            {info.description}
        </ModalContent>
      </ContentRow>
      <CloseButton onClick={onClose} />
        <InterestedToast>
            {isFinnish ? <span>
                Kiinnostuitko? Ota yhteyttä <a href="mailto:sales@haltialakelodge.com">sales@haltialakelodge.com</a>
            </span> : <span>
                Interested? Contact <a href="mailto:sales@haltialakelodge.com">sales@haltialakelodge.com</a>
            </span>}
        </InterestedToast>
    </StyledModal>
  </>
  }
  </TranslationContext.Consumer>
}


export default injectIntl(ExperienceModal)