import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import get from "lodash/get"
import merge from "lodash/merge"
import { contentOptions } from "../rich-text"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import Layout from "../components/layout"
import Button from "../components/button"
import Reference from "../components/reference"
import Map from "../components/map"
import ExperienceModal from "../components/experience-modal"
import { StyledPageContent, PageTitle, PageSubtitle } from "../components/page-section"
import { colors, WrappedWords } from "../theme"
import { TranslationContext } from "../translate"
import { injectIntl } from "gatsby-plugin-intl"

import sliderArrowImage from "../images/slider-arrow.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-image-lightbox/style.css"

const HeroImage = styled.div`
  min-height: 15em;
  background: transparent url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  @media (min-width: 768px) {
    min-height: 20em;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 769px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const ContentImage = styled.img`
  display: none;
  object-fit: cover;
  flex: 1;
  margin-bottom: 1em;
  @media (min-width: 769px) {
    display: block;
    max-width: 33.33%;
    margin-right: 2em;
    margin-bottom: 0;
  }
`

const ContentText = styled.div`
  flex: 1;
  text-align: left;
  white-space: pre-wrap;
  p {
    margin: 1em 0;
    line-height: 1.5em;
    &:first-child {
      margin-top: 0;
      font-style: italic;
      color: ${colors.kaisla};
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 769px) {
    padding-bottom: ${({ hasPageImages }) => (hasPageImages ? "2em" : "0em")};
  }
`

const SliderImageWrapper = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  padding-top: 60%;
`

const SliderImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`

const SliderArrowRight = styled.div`
  font-size: inherit !important;
  width: 2em;
  height: 2em;
  right: -3em;
  transform: translateY(-50%);
  background: transparent url(${sliderArrowImage}) no-repeat center center;
  background-size: contain;
  &:before {
    content: none !important;
    display: none !important;
  }
  &:hover {
    background: transparent url(${sliderArrowImage}) no-repeat center center;
    background-size: contain;
    opacity: 0.5;
  }
  @media (max-width: 980px) {
    display: none !important;
  }
`

const SliderArrowLeft = styled(SliderArrowRight)`
  transform: translateY(-50%) scaleX(-1);
  right: auto;
  left: -3em;
`

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  margin-bottom: 1.5em;
`

const EventNameDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.pakuri};
  color: #fff;
  font-weight: bold;
  justify-content: center;
  padding: 0.5em;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const EventName = styled.div`
  margin: 0 0.5em;
`

const EventDate = styled.div`
  margin: 0 0.5em;
  color: ${colors.kaisla};
`

const EventLocation = styled.div`
  background: ${colors.background.Light};
  color: ${colors.kaisla};
  padding: 0.5em;
  font-size: 0.9em;
  text-align: center;
`

const bigButtonStyles = {
  fontSize: "1.3em",
  maxWidth: "16em",
  transform: "translateY(50%)",
  margin: "0 auto",
  position: "relative",
  borderWidth: "3px",
}

const Spacer = styled.div`
  height: 4em;
`




const ExperienceCardsWrapper = styled.div`
  display: block;
  width: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: #f0f0f0;
`

const ExperienceCardsWrapperInner = styled.div`
  display: block;
  width: 100%;
  max-width: 60em;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4em 2em;
  gap: 1em;
  @media (min-width: 768px) {
    justify-content: start;
  }
`

const ExperienceCard = styled.a`
  display: block;
  width: 19.33em;
  box-shadow: 0 0 0.5em rgba(0,0,0,0.2);
  color: #303030;
  text-decoration: none;
  background: #fff;
  &:hover {
    color: #606060;
  }
`

const ExperienceCardInfo = styled.div`
  padding: 1em;
`

const ExperienceCardTitle = styled.h3`
  display: block;
  margin: 0;
`

const ExperienceCardDescription = styled.p`
  display: block;
  line-height: 1.5em;
  text-overflow: ellipsis ellipsis;
  max-height: 3em;
  width: 100%;
  overflow: hidden;
`

const ExperienceCardCoverImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  height: 12em;
`




const Page = ({ pageContext, data, location, intl }) => {
  const page = data.contentfulPage
  const headerImage = get(page, "headerImage.localFile.childImageSharp.fixed.src")
  const content = get(page, "content")
  const title = get(page, "title")
  const subtitle = get(page, "subtitle")
  const color = get(page, "color")
  const contentImage = get(page, "contentImage.localFile.childImageSharp.fixed.src")
  const images = get(page, "images")
  const sections = get(page, "sections") || []
  const showMap = get(page, "showMap")
  const showMapBottom = get(page, "showMapBottom")
  const showDatahubCards = get(page, "showDatahubCards")
  const headerButton = get(page, "headerButton")
  const eventName = get(page, "eventName")
  const eventDate = get(page, "eventDate")
  const eventLocation = get(page, "eventLocation")
  const headerButtonStyles = merge(bigButtonStyles, {marginTop: "-2.5em", zIndex: 1});
  const headerButtonEl = headerButton && <Button {...headerButton} primary color={colors.pakuri} style={headerButtonStyles} />
  const heroContent = headerImage ? <><HeroImage src={headerImage} />{headerButtonEl}</> : null
  const [currentLightboxImage, setCurrentLightboxImage] = useState(null)

  const isFinnish = intl.locale === "fi"
  const layoutSettings = get(data, 'allContentfulLayoutSettings.edges[0].node')
  const experiences = get(data, 'allExperience.edges')
  const excludedDatahubCardTitles = get(layoutSettings, 'excludedDatahubCardTitles')

  const [selectedExperience, setSelectedExperience] = useState(null)

  return (
    <Layout data={data} heroContent={heroContent} location={location}>
    <TranslationContext.Consumer>{(t) =>
      <>
      {showMap && <Map />}
      <StyledPageContent color={color}>
        <PageTitle>
          <WrappedWords text={t(title)} />
        </PageTitle>
        {subtitle ? <PageSubtitle>{t(subtitle)}</PageSubtitle> : <Spacer />}

        {(eventName || eventDate || eventDate) && <EventInfo>
          {(eventName || eventDate) && <EventNameDateWrapper>
            {eventName && <EventName>
              {t(eventName)}
            </EventName>}
            {eventDate && <EventDate>
              {moment(eventDate).format("DD.MM.YYYY")}
            </EventDate>}
          </EventNameDateWrapper>}
          {eventLocation && <EventLocation>
            {t(eventLocation)}
          </EventLocation>}
        </EventInfo>}
        
        <ContentWrapper>
          <Content>
            {contentImage && <ContentImage src={contentImage} />}
            <ContentText hasPageImages={images && images.length > 0}>
              {content && renderRichText(t(content, true), contentOptions)}
            </ContentText>
          </Content>

          {images && images.length > 0 && 
            <Slider 
              slidesToShow={3} 
              slidesToScroll={1} 
              infinite 
              autoplay 
              speed={750} 
              autoplaySpeed={5000} 
              pauseOnHover={false}
              arrows={true}
              nextArrow={<SliderArrowRight src={sliderArrowImage} />}
              prevArrow={<SliderArrowLeft src={sliderArrowImage} />}
            >
              {images.filter((img => img && img.localFile)).map((image, i) =>
                <SliderImageWrapper key={i}>
                  <SliderImage src={image.localFile.childImageSharp.fixed.src} onClick={(e) => {e.preventDefault(); setCurrentLightboxImage(image.localFile.childImageSharp.fixed.src)}} />
                </SliderImageWrapper>
              )}
            </Slider>
          }
        </ContentWrapper>

      </StyledPageContent>
      {sections &&
        sections.map((section, i) => {
          const props = {}
          if (section.__typename === "ContentfulButton") {
            props.primary = true
            props.color = colors.pakuri
            props.style = bigButtonStyles
          }
          return <Reference key={i} {...section} {...props} />
        })}
        {currentLightboxImage &&
          <Lightbox
            mainSrc={currentLightboxImage}
            onCloseRequest={() => setCurrentLightboxImage(null)}
            />
        }
      {showMapBottom && <Map />}
      {showDatahubCards && <><ExperienceCardsWrapper><ExperienceCardsWrapperInner>
        {experiences.filter(({node}) => {
          let info = node.productInformations.find((i) => i.language === (isFinnish ? "fi" : "en"))
          return excludedDatahubCardTitles.indexOf(info.name) < 0
        }).map(({node}) => {
            let info = node.productInformations.find((i) => i.language === (isFinnish ? "fi" : "en"))
            let coverImage = node.productImages.find((i) => i.coverPhoto === true) || node.productImages[0]
            return <ExperienceCard href='' onClick={(e) => {e.preventDefault(); setSelectedExperience(node)}}>
                <ExperienceCardCoverImage src={coverImage.thumbnailUrl} />
                <ExperienceCardInfo>
                    <ExperienceCardTitle>{info.name}</ExperienceCardTitle>
                    <ExperienceCardDescription>{info.description.substring(0, 70)}...</ExperienceCardDescription>
                </ExperienceCardInfo>
            </ExperienceCard>
          })}
        </ExperienceCardsWrapperInner></ExperienceCardsWrapper>
        {selectedExperience && <ExperienceModal experience={selectedExperience} onClose={() => setSelectedExperience(null)} />}
        </>}
      </>
    }</TranslationContext.Consumer>
    </Layout>
  )
}

export const query = graphql`
  query ContentfulPage($slug: String, $locale: String) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      node_locale
      title
      subtitle
      headerImage {
        localFile {
          childImageSharp {
            fixed(width: 1440) {
              src
            }
          }
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            localFile {
              publicURL
            }
            localFile {
              childImageSharp {
                fixed(width: 1440) {
                  src
                }
              }
            }
          }
        }
      }
      contentImage {
        localFile {
          childImageSharp {
            fixed(height: 1500) {
              src
            }
          }
        }
      }
      images {
        ... on ContentfulAsset {
          localFile {
            childImageSharp {
              fixed(width: 1440) {
                src
              }
            }
          }
        }
      }
      color
      sections {
        ... on Node {
          __typename
          ...PageSection
          ...Button
          ...Card
          ...Form
          ...Post
          ...ImageGallery
        }
      }
      showMap
      showMapBottom
      ogTitle
      ogDescription
      ogImage {
        localFile {
          childImageSharp {
            fixed(width: 900) {
              src
            }
          }
        }
      }
      headerButton {
        ...Button
      }
      eventName
      eventDate
      eventLocation
      showDatahubCards
    }

    allContentfulHomeSection(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          identifier
          title
          subtitle
          order
          primaryNavigation
        }
      }
    }

    allContentfulLayoutSettings(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          introLine1
          introLine2
          introContent {
            introContent
          }
          primaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          secondaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          tertiaryLogo {
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  src
                  srcSet
                }
              }
            }
          }
          headerVideo {
            localFile {
              publicURL
            }
          }
          headerVideoWebm {
            localFile {
              publicURL
            }
          }
          cookieConsentText
          cookieConsentButtonText
          sideMenuButtons {
            ...Button
          }
          primaryMenuButtons {
            ...Button
          }
          secondaryMenuButtons {
            ...Button
          }
          footerMenuButtons {
            ...Button
          }
          ogTitle
          ogDescription
          ogImage {
            localFile {
              childImageSharp {
                fixed(width: 900) {
                  src
                }
              }
            }
          }
          serviceNotification
          showNotificationBanner
          notificationBannerContent {
            raw
          }
          excludedDatahubCardTitles
        }
      }
    }
    allContentfulContactInformation(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          icon {
            localFile {
              publicURL
            }
          }
          order
          linkUrl
          label
        }
      }
    }
    allContentfulSocialMediaLink(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          url
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allExperience {
      edges {
        node {
          id
          productImages {
            filename
            thumbnailUrl
            coverPhoto
          }
          productInformations {
            name
            description
            webshopUrl
            language
            url
          }
          productPricings {
            toPrice
            pricingUnit
            pricingType
            fromPrice
          }
          productCapacities {
            max
            min
          }
          duration
          durationType
        }
      }
    }
  }
`

export default injectIntl(Page)
